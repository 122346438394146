<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Tambah Dokumen Customer" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Tgl Terima *</label>
            <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Dokumen Customer *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Jenis Dokumen Customer" :value="data.nama_item_dokumen_customer" @click="isModalItemDokumenCustomerActive = true" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalItemDokumenCustomerActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">File *</label>
            <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" v-model="data.fileTemp" @change="data.file = $event.target.files[0]"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal hadiah-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Jenis Dokumen"
                :active="isModalItemDokumenCustomerActive"
                v-on:update:active="isModalItemDokumenCustomerActive = $event">
        <ItemDokumenCustomer v-if="isActive" :selectable="true" @selected="onSelectedModalItemDokumenCustomer"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import KontrakItemDokumenCustomerRepository from '@/repositories/marketing/kontrak/kontrak-dokumen-customer-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import ItemDokumenCustomer from '@/views/pages/master/item-dokumen-customer/ItemDokumenCustomer'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'DokumenCustomerAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    flatPickr,
    ItemDokumenCustomer
  },
  data () {
    return {
      isLoading: false,
      isModalItemDokumenCustomerActive: false,
      errors: null,
      data: {
        tgl: moment().format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    onSelectedModalItemDokumenCustomer (item) {
      this.data.id_item_dokumen_customer = item.id
      this.data.nama_item_dokumen_customer = item.nama
      this.isModalItemDokumenCustomerActive = false
    },

    save () {
      this.errors = null
      this.isLoading = true
      const params = convertToFormData({ ...this.data, id_kontrak: this.$route.params.idKontrak })
      const idKontrak = this.$route.params.idKontrak

      KontrakItemDokumenCustomerRepository.create(idKontrak, params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
